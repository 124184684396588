import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../commonComponents/forms/inputs/Select';
import TenantProp from '../../../../commonComponents/propTypes/tenantProp';
import { REGISTER_TYPE, REGISTER_TYPES } from '../../helpers/vodHelper';
import CustomCheckbox from '../../../../commonComponents/forms/inputs/CustomCheckbox';
import FormInput from '../../../../commonComponents/forms/FormInput';
import Branding from './Branding';

function TabVod(props) {
  const { t, tenant, _csrf, isTenant } = props;
  const {
    enabled: enabledParam,
    accessibility: accessibilityParam,
    branding: brandingParam,
  } = tenant?.settings?.vod || {};

  const [VOD, setVOD] = useState({
    accessibility: accessibilityParam || REGISTER_TYPE.INVITE,
    enabled: enabledParam || false,
    branding: brandingParam || {
      mode: 'all',
      light: {},
      dark: {},
    },
  });
  const { enabled, accessibility, branding } = VOD;
  const {
    mode = 'all',
    appName = '',
    light: lightBranding,
    dark: darkBranding,
  } = branding || {};
  const hasLightMode = mode === 'all' || mode === 'light';
  const hasDarkMode = mode === 'all' || mode === 'dark';

  const [isCollapsed, setIsCollapsed] = useState({
    light: true,
    dark: true,
  });
  const handleCollapsedClick = useCallback(
    theme => {
      setIsCollapsed(prevState => ({
        ...prevState,
        [theme]: !prevState?.[theme],
      }));
    },
    [setIsCollapsed],
  );

  const handleChange = useCallback(
    (field, field2) => value => {
      switch (field) {
        case 'appName':
        case 'mode':
          setVOD(prevVOD => ({
            ...prevVOD,
            branding: {
              ...prevVOD.branding,
              [field]: value,
            },
          }));
          break;
        case 'light':
        case 'dark':
          setVOD(prevVOD => ({
            ...prevVOD,
            branding: {
              ...prevVOD.branding,
              [field]: {
                ...prevVOD.branding?.[field],
                [field2]: value,
              },
            },
          }));
          break;
        case 'enabled':
          setVOD(prevVOD => ({ ...prevVOD, enabled: !prevVOD.enabled }));
          break;
        case 'accessibility':
        default:
          setVOD(prevVOD => ({ ...prevVOD, [field]: value }));
          break;
      }
    },
    [setVOD],
  );

  return (
    <div className="vod-tab-container">
      <input type="hidden" name="vod" id="vod" value={JSON.stringify(VOD)} />
      <h4>{t('tenant.vod.title')}</h4>
      {isTenant && (
        <>
          <div className="form-group m-form__group">
            <label
              className="control-label col-md-3 text-right"
              htmlFor="accessibility"
            >
              {t('tenant.vod.enabled.label')}
            </label>
            <div className="col-md-4">
              <CustomCheckbox
                id="enabled"
                checked={enabled}
                onClick={handleChange('enabled')}
              />
            </div>
          </div>

          <div className="form-group m-form__group">
            <label
              className="control-label col-md-3 text-right"
              htmlFor="accessibility"
            >
              {t('tenant.vod.access.label')}
            </label>
            <div className="col-md-4">
              <Select
                id="accessibility"
                name={accessibility}
                placeholder={t('tenant.vod.access.label')}
                onChange={handleChange('accessibility')}
                value={accessibility}
                width="100%"
                className="form-control m-input"
                items={REGISTER_TYPES.map(type => ({
                  id: type,
                  text: t(`tenant.vod.access.type.${type}`),
                }))}
              />
              <p className="m-form__help">
                {t(`tenant.vod.access.type.${accessibility}.hint`)}
              </p>
            </div>
          </div>
        </>
      )}
      <FormInput
        id="vodAppName"
        name="vodAppName"
        label={t('tenant_appName')}
        onChange={handleChange('appName')}
        value={appName}
        width="100%"
        placeholder={t('tenant_appName_placeholder')}
        labelClassName="control-label col-md-3 text-right"
        containerClassName="col-md-4"
        className="form-control m-input"
      />
      <hr />
      <h4 className="vod-branding-title">VOD {t('tenant_branding')}</h4>
      <FormInput
        id="mode"
        value={mode}
        name="mode"
        labelClassName="control-label col-md-3"
        containerClassName="col-md-4"
        className="form-control m-input"
        type="select"
        groupProps={{ className: 'mode-container' }}
        label={t('tenant_mode')}
        placeholder={t('tenant_mode_placeholder')}
        onChange={handleChange('mode')}
        inputProps={{
          width: '100%',
          multiple: false,
          items: [
            {
              id: 'all',
              text: t('tenant_mode_all'),
            },
            {
              id: 'light',
              text: t('tenant_mode_light'),
            },
            {
              id: 'dark',
              text: t('tenant_mode_dark'),
            },
          ],
        }}
      />
      <Branding
        hasMode={hasLightMode}
        mode="light"
        inputId="light"
        t={t}
        onChange={handleChange}
        onCollapsedClick={handleCollapsedClick}
        isCollapsed={isCollapsed}
        _csrf={_csrf}
        branding={lightBranding}
      />
      <Branding
        hasMode={hasDarkMode}
        mode="dark"
        inputId="dark"
        t={t}
        onChange={handleChange}
        onCollapsedClick={handleCollapsedClick}
        isCollapsed={isCollapsed}
        _csrf={_csrf}
        branding={darkBranding}
      />
    </div>
  );
}

TabVod.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  tenant: PropTypes.shape(TenantProp.propType).isRequired,
  isTenant: PropTypes.bool,
  inputId: PropTypes.string,
};

TabVod.defaultProps = {
  isTenant: true,
  inputId: null,
};

export default TabVod;
