import React from 'react';
import PropTypes from 'prop-types';
import TabBranding from '../../configurations/general/TabBranding';
import BrandingProp from '../../configurations/propTypes/brandingProp';

function Branding(props) {
  const {
    t,
    hasMode,
    mode,
    onCollapsedClick,
    isCollapsed,
    branding,
    _csrf,
    inputId,
    onChange,
  } = props;

  if (!hasMode) {
    return null;
  }

  return (
    <section className="themeBranding">
      <div className="header">
        <button type="button" onClick={() => onCollapsedClick(mode)}>
          <i
            className={`fa ${
              isCollapsed[mode] ? 'fa-angle-down' : 'fa-angle-up'
            }`}
          />
        </button>
        <h5 className="title">{t(`theme.mode.${mode}`)}</h5>
      </div>
      {!isCollapsed[mode] && (
        <TabBranding
          branding={branding}
          onChange={field => onChange(mode, field)}
          _csrf={_csrf}
          tabId={`vodBranding_${mode}`}
          t={t}
          isVOD
          inputId={inputId}
        />
      )}
    </section>
  );
}

Branding.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  hasMode: PropTypes.bool,
  onCollapsedClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  inputId: PropTypes.string.isRequired,
  branding: PropTypes.shape(BrandingProp.propType),
};

Branding.defaultProps = {
  hasMode: true,
  isCollapsed: true,
  branding: PropTypes.shape(BrandingProp.defaultProp),
};

export default Branding;
