import React from 'react';
import PropTypes from 'prop-types';

export default function CustomCheckbox({
  id,
  onChange,
  onClick,
  checked,
  disabled,
  label,
  value,
  checkedProp,
}) {
  return (
    <label
      className="custom-checkbox-container"
      htmlFor={`radio-${id}`}
      aria-disabled={disabled}
    >
      <input
        id={`radio-${id}`}
        name={`radio-${id}`}
        value={value || id}
        type="checkbox"
        onChange={onChange}
        onClick={onClick}
        defaultChecked={checked}
        disabled={disabled}
        checked={checkedProp}
      />
      <span className="checkmark" />
      {label && <p>{label}</p>}
    </label>
  );
}

CustomCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  checkedProp: PropTypes.bool,
};

CustomCheckbox.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  checked: false,
  disabled: false,
  label: null,
  value: null,
  checkedProp: undefined,
};
